<template>
  <div v-if="reservationData && amphiEvent">
    <stepper-custom :step="4"></stepper-custom>
    <v-container>
      <v-row class="my-10">
        <v-col cols="0" md="2"
               class="text-center">
          <v-icon class="text-h1 success--text">mdi-check-circle</v-icon>
        </v-col>
        <v-col>
          <v-card
              color="#F8F8F8"
              tile
              elevation="0"
          >
            <v-card-title>¡ENHORABUENA!</v-card-title>
            <v-card-subtitle>Has realizado la reserva exitosamente.</v-card-subtitle>
            <v-card-text>Te hemos enviado un mail con los datos de tu reserva</v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <div class="text-uppercase font-weight-black primary--text text-h5 mb-1"> Detalle de la Reserva</div>
      </v-row>
      <v-row>
        <v-card
            color="#F8F8F8"
            tile
            elevation="0"
            width="100%"
        >
          <v-carousel
              :height="getHeight()"
              show-arrows-on-hover
              v-model="ticketSelected"
          >
            <v-carousel-item
                v-for="ticket in reservationData.tickets"
                :key="ticket.id"
            >
              <v-sheet
                  color="#F8F8F8"
                  class="pa-6"
                  height="100%"
                  tile
              >
                <v-row class="fill-height"
                       align="top"
                       justify="center"
                >
                  <v-col>
                    <div v-for="(item,index) in resume" :key="index">
                      <p class="font-weight-light text-uppercase mb-0">
                        {{ item.label }}
                      </p>
                      <p class="font-weight-black">
                        {{ item.value }}
                      </p>
                    </div>
                  </v-col>
                  <v-col>
                    <div>
                      <p class="font-weight-light text-uppercase mb-0">
                        N° de Recibo
                      </p>
                      <p class="font-weight-black">
                        {{ reservationData.receipt }}
                      </p>
                    </div>
                    <div>
                      <p class="font-weight-light text-uppercase mb-0">
                        codigo de reserva
                      </p>
                      <p class="font-weight-black">
                        {{ ticket.code }}
                      </p>
                    </div>
                    <v-card
                        elevation="0"
                        color="#24B0E00D"
                        tile
                    >
                      <v-card-text class="text-uppercase primary-text">Ingreso</v-card-text>
                      <v-card-subtitle>Recuerda que debes presentarte con 15 minutos de anticipación. Recuerda tener el código QR a mano o el Código de reserva para ingresar.</v-card-subtitle>

                    </v-card>
                  </v-col>
                  <v-col class="text-center">
                    <div  v-html='ticket.QR.replaceAll(`="100"`,`="50%"`)'></div>
                    <v-btn class="primary my-7" tile elevation="0" @click="downloadTicket(ticket.id)">Descargar Entrada</v-btn>
                  </v-col>
                </v-row>
              </v-sheet>

            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {AmphiEvents, Reservation} from "@/services/api";
import StepperCustom from "@/components/StepperCustom";

export default {
  name: "Confirm",
  components: {StepperCustom},
  data() {
    return {
      reservationData: null,
      model: null,
      amphiEvent: null,
      ticketSelected: 0
    }
  },
  computed: {
    user(){
      return this.$store.state.user || {}
    },
    requestReservation() {
      return  Reservation(this.user.token_type, this.user.access_token)
    },
    reserveId() {
      return this.$route.query.id || null
    },
    resume() {
      const reservation = this.reservationData
      const amphiEvent = this.amphiEvent
      const amphiDate = this.getDate(reservation.event_schedule_id.schedule)
      return [
        {
          label: 'Evento',
          value: amphiEvent.name
        }, {
          label: 'Fecha',
          value: amphiDate.date
        }, {
          label: 'Horario',
          value: amphiDate.hour
        }, {
          label: 'Mesa',
          value: reservation.amphi_table.name
        }, {
          label: 'Asientos',
          value: reservation.amphi_table.capacity
        },
      ]
    }
  },
  mounted() {
    if (this.isMobile()) {
      this.postMessage();
      setTimeout(this.getReservationData,5000)
    } else {
      this.getReservationData();
    }
  },
  metaInfo() {
    return {
      title: `✔️Reserva Confirmada | ${this.amphiEvent ? this.amphiEvent.name : ''}`,
      meta: [
        {name: 'description', content: 'Saca tus entrdadas de forma facil y dinamica'},
      ]
    }
  },
  methods: {
    postMessage() {
      let msg = {
        id: this.reserveId
      }
      // send event to iOS
      if (!!window.webkit && !!window.webkit.messageHandlers) {
        window.webkit.messageHandlers.appInterface.postMessage(JSON.stringify(msg));
      }
      // send event to Android
      // eslint-disable-next-line no-undef
      if (typeof Android !== 'undefined') {
        // eslint-disable-next-line no-undef
        Android.postMessage(JSON.stringify(msg))
      }
    },
    getReservationData() {
      this.requestReservation.get(this.reserveId).then(
          response => {this.reservationData = response.data.data
          console.log("PASO 1")}
      ).then(() => {
        console.log("Paso 2")
            AmphiEvents(this.user.token_type, this.user.access_token).getEvent(this.reservationData.event_schedule_id.amphi_event_id).then(
                res => this.amphiEvent = res.data.data
            ).finally(() =>
                this.$store.commit('setLoading', false)
            )
          }

      )
    },
    getDate(scheduleDate){
      let date = scheduleDate.split('T')
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
      let eventDate = new Date(date[0])
      return {
        date: eventDate.toLocaleString('es-es', options),
        hour: date[1].substr(0, 5)
      }
    },
    downloadTicket(id){
      this.requestReservation.pkpass(id).then(
          (response) => {
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `La-Marina-${this.amphiEvent.name.replaceAll(' ','-')}-${id}.pkpass`); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
    },
    getHeight(){
      return this.isMobile() ? 1000 : 500
    }
  }
}
</script>

<style scoped>

</style>